import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { DashboardLayout } from "../layouts";
import { SignIn } from "../modules/authScreen";
import ChecklistSummary from "../modules/ChecklistSummary";
import HomeScreen from "../modules/HomeScreen";
import OwnerInfo from "../modules/OwnerInfo";
import OwnerProfile from "../modules/OwnerInfo/OwnerProfile";
import OwnerTab from "../modules/OwnerInfo/OwnerTab";
import { changeScreen } from "../_helpers";
import ProtectedRoute from "./ProtectedRoute";
import ScrollableTabsButtonPrevent from "../_components/TabsHWA";
import { CentralHospitalList } from "../central/screens/CentralHospitalList/index.js";
import { CentralPatientList } from "../central/screens/CentralPatientList/index.js";
import { CentralPatientDetails } from "../central/screens/CentralPatientDetails/index.js";
import PatientJourney from "../central/screens/PatientJourney/index.js";
import CentralComplatins from "../central/screens/CentralComplatins/index.js";


const Routing = () => {
  const [currentPatId, setCurrentPatId] = useState("");
  const [currentHospId, setCurrentHospId] = useState("");
  const [currentSid, setCurrentSid] = useState("");
  const loggedIn = useSelector((state) => state?.authentication?.loggedIn);
  
  const loggedIn1 = useSelector((state) => state?.authentication?.user.authorities);
  const role = useSelector(state => state?.authentication?.user?.roles?.role)
  

  
  const { pathname } = useLocation()
  const totalHospitals = useSelector((state) => state.hospitals.totalHospitals);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    var url = window.location.href;
    url = url.replace(window.location.origin, "");
    const queryParams = new URLSearchParams(url);
    let hospId = "";
    let patId = "";
    let sid = "";
    for (let p of queryParams) {
      if (p[0].includes("hospId")) {
        hospId = p[1];
        setCurrentHospId(hospId);
      }
      if (p[0].includes("patId")) {
        patId = p[1];
        setCurrentPatId(patId);
      }
      if (p[0].includes("sid")) {
        sid = p[1];
        setCurrentSid(sid);
      }
    }
  }, []);
  useEffect(() => {
    console.log('this is the pathNAME', pathname)

    if (pathname === '/home') {
      if (totalHospitals === 1) {
        changeScreen(dispatch, navigate, { hospitalList: false, patientList: true, chatBox: false }, 'patients')
      }

    }
  }, [pathname, totalHospitals])

  return (
    <>
      <Routes>
        <Route path="/" element={<ProtectedRoute ><DashboardLayout /></ProtectedRoute>}>

          <Route index element={<Navigate to={'/home'} />} />
          <Route path="/checklistSummary" element={<ChecklistSummary />} />
          <Route path="/checklistSummary/patient" element={<ChecklistSummary />} />
          <Route path="/checklistSummary/hospitalView" element={<ScrollableTabsButtonPrevent />} />
          <Route path="/home" element={<HomeScreen hospId={currentHospId} patId={currentPatId} sid={currentSid} />} />
          <Route path="/patients" element={<HomeScreen hospId={currentHospId} patId={currentPatId} sid={currentSid} />} />
          <Route path="/chatbox" element={<HomeScreen hospId={currentHospId} patId={currentPatId} sid={currentSid} />} />
          <Route path="/central" element={<CentralHospitalList/>}></Route>
          <Route path="/central/patientJourney" element={<PatientJourney/>}></Route>
          <Route path="/central/:hospitalId/:patientId/complaints" element={<CentralComplatins/>}></Route>
          <Route path="/central/:hospitalId" element={<CentralPatientList/>}></Route>
          <Route path="/central/:hospitalId/:patientId" element={<CentralPatientDetails/>}></Route>
          <Route path="/owner" element={<OwnerInfo title="Performance Report" />} >
            <Route index element={<OwnerTab />} />
            <Route path="profile" element={<OwnerProfile />} />
          </Route>
          

        </Route>

        {!loggedIn && <Route path="/login" element={<SignIn />} />}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </>
  )
}
export default Routing