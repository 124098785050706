import React, { useEffect, useState } from 'react'
import { CentralAccordion } from '../../component/CentralAccordion/CentralAccordion'
import { useDispatch, useSelector } from 'react-redux';
import { centralCommonDataActions, centralPatientActions, centralReportingDataActions, configActions } from '../../../_actions';
import { CentralCommonHeader } from '../../component/CommonHeader/CentralCommonHeader';
import { useNavigate } from 'react-router-dom';
import { centralPatientConstants } from '../../../_constants/centralConstant/centralPatient.constant';
import { centralComplaintsActions } from '../../../_actions/centralActions/centralComplaints.actions';
import { hospitalDataConstants } from '../../../_constants';
import { Button, CircularProgress } from '@mui/material';
import { statusCategories } from '../../helpers/utilities';
import './centralPatientDetails.css'
import { centralEquipmentsActions } from '../../../_actions/centralActions/centralEquipments.actions';

export const CentralPatientDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  let commonData = useSelector(state => state.centralCommonData.commonData)
  let activeHospitalId = useSelector(state=>state.centralHospitalData.activeHospitalId)

  let patientList = useSelector(state => state.centralPatients)
  let fetchedReportedData = useSelector(state => state.centralCommonData.commonData?.result.fetched)
  const reportingData = useSelector(state=>state.centralReportingData?.reportingData);
  const [activePatientName, setActivePatientName] = useState(patientList.activePatientName || '')
  const [patientJourneyTag,setPatientJourneyTag] = useState('')
  const [selectedColorObj,setSelectedColorObj] = useState([])
  const [activePatientId, setActivePatientId] = useState('');

  useEffect(() => {
    const parts = window.location.pathname.split('/');
    const id = parts[3]; 
    if(reportingData.message==='Success' && reportingData?.data?.length>0 && reportingData.data[0].appointment_id===id){
      const appointmentId = reportingData.data[0].appointment_id || reportingData.data[0]._id;
      setActivePatientId(appointmentId);
      setActivePatientName(reportingData.data[0].patient_name)
      activeHospitalId = reportingData.data[0].hospitalId

      reportingData?.data?.forEach(element => {
        if(element.appointment_id === activePatientId){
          const colorObj = statusCategories.filter((obj) =>
            obj.statuses.includes(element.patientJourneyTag)
          );
          setPatientJourneyTag(element.patientJourneyTag ? element.patientJourneyTag : '')
          setSelectedColorObj(colorObj)
        }
    });
    }
  }, [reportingData])
  
  const jsonData = commonData?.result?.data
  useEffect(() => {
   dispatch(centralCommonDataActions.getCentralCommonData())
   return ()=>dispatch(centralCommonDataActions.clearCentralCommonData())
  }, []);
  
  useEffect( () => {
    const parts = window.location.pathname.split('/');
      const id = parts[3]; 
      setActivePatientId(id);
      activeHospitalId = parts[2]
     dispatch(centralReportingDataActions.getCentralReportingData({"search_query":id}));
     dispatch(centralComplaintsActions.getCentralComplaintsList({"appId":id}))
     dispatch(
      centralEquipmentsActions.getCentralInstrumentsList({
        appId: id,
      })
    );
     if(window.location.search.includes("accessToken")){
       dispatch(centralPatientActions.getCentralPatientList({hospitalId:activeHospitalId}));
     }
  }, [activePatientId && jsonData]);

  useEffect(() => {
    activePatientName !== "" && dispatch({
        type:centralPatientConstants.SAVE_ACTIVE_CENTRAL_PATIENT_DATA,
        payload:{patientId:activePatientId,patientName:activePatientName}
    })
  }, [activePatientId, activePatientName])

  const [accordionHeaders,SetAccordionHeaders] = useState([])

  useEffect(()=>{
    if(jsonData){
        let tempArr = jsonData.map((item)=> {return item.title})
        if(tempArr && tempArr.length>0){
          SetAccordionHeaders(tempArr)
        }
    }
  },[fetchedReportedData])

  const handleBackButton = () =>{
    const parts = window.location.pathname.split('/');
    activeHospitalId = parts[2] || reportingData.data[0].hospitalId
    const hospitalName = reportingData?.data[0]?.hospitalName?.value
    if(activeHospitalId && hospitalName){
      dispatch({
          type:hospitalDataConstants.SAVE_ACTIVE_CENTRAL_HOSPITAL_DATA,
          payload:{id:activeHospitalId,hospitalName}
      })
  }
    navigate(`/central/${activeHospitalId}`)
    dispatch(centralCommonDataActions.clearCentralCommonData())
    dispatch({
      type:centralPatientConstants.SAVE_ACTIVE_CENTRAL_PATIENT_DATA,
      payload:null
  })
  }

  const handleJourneyNavigation = ()=>{
      navigate(`/central/patientJourney`)
  }

  return (
    <>
        <CentralCommonHeader title={activePatientName} backIcon={true} backArrowFunction = {handleBackButton} avatarIcon = {true} isChatIcon = {true}/>
        <div className='patientDetails_header'>
            {patientJourneyTag!='' ? <div className='patientDetails_statusCard' style={{backgroundColor:`${(selectedColorObj && selectedColorObj.length>0) ? selectedColorObj[0]?.color : ''}`,border:`0.1px solid ${(selectedColorObj && selectedColorObj.length>0) ? selectedColorObj[0]?.color : ''}`}}>
                {patientJourneyTag}
            </div> : <div></div>}
            <Button className='patientDetails_journeyButton' onClick={()=>handleJourneyNavigation()}>
                Patient Journey
            </Button>
        </div>
        <div className='patientDetailsMainContainer'>
  {fetchedReportedData && accordionHeaders && accordionHeaders.length > 0 && jsonData ? (
    <>
      {accordionHeaders.map((item, index) => (
        <CentralAccordion key={index} type={item} data={jsonData} />
      ))}
    </>
  ) : (
    <div className='patientDetailsLoaderContainer'>
      <CircularProgress />
    </div>
  )}
</div>
    </>
  )
}
