import React, { useEffect, useState } from 'react'
import { arr } from '../../component/jsonConstant';
import CentralComplaintSection from '../../component/CentralComplaintSection/CentralComplaintSection';
import { CentralCommonHeader } from '../../component/CommonHeader/CentralCommonHeader';
import { useDispatch, useSelector } from 'react-redux';
import { centralCommonDataActions, centralPatientActions, centralReportingDataActions } from '../../../_actions';
import { centralPatientConstants } from '../../../_constants/centralConstant/centralPatient.constant';
import { hospitalDataConstants } from '../../../_constants';
import { useLocation, useNavigate } from 'react-router-dom';

function CentralComplatins() {
  const location = useLocation();
  const data = location.state?.arrValue || arr || [];
  const editData = location.state?.editData || null
  console.log('details...', data, editData);

  const navigate = useNavigate();

  let patientList = useSelector(state => state.centralPatients)
  let activeHospitalId = useSelector(state=>state.centralHospitalData.activeHospitalId) 
  const [activePatientName, setActivePatientName] = useState(patientList.activePatientName || '')
  
  const handleBackButton = () =>{
      navigate(`/central/${activeHospitalId}/${patientList?.activePatientId}`)
  }

  return (
    <>
      <CentralCommonHeader title={activePatientName} backIcon={true} backArrowFunction = {handleBackButton} avatarIcon = {true} isChatIcon = {true}/>
      <CentralComplaintSection data={data} id={patientList?.activePatientId} prefilledData={editData} />
    </>
  )
}

export default CentralComplatins