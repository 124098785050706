import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
import { cacheStorage } from "./cacheStorage.reducer";
import { chatBox } from "./chatBox.reducer";
import { checklistSummary } from "./checklist.reducer";
import { config } from "./config.reducer";
import { documentsPopup } from "./documentsPopup.reducer";
import { groupInfoPopup } from "./groupInfoPopup.reducer";
import { homeState } from "./homeState.reducer";
import { hospitals } from "./hospital.reducer";
import { patients } from "./patient.reducer";
import { pcScore } from './pcScore.reducer';
import { socketConnectivity } from "./socket.reducer";
import { users } from "./users.reducer";

import { centralCommonData } from "./centralReducers/centralCommonData.reducer";
import { centralPatients } from "./centralReducers/centralPatient.reducers";
import { centralReportingData } from "./centralReducers/reportingData.reducer";
import {centralHospitalData} from "./centralReducers/centralHospital.reducer"
import { booleanStates } from "./centralReducers/booleanStates.reducer";
import { centralInstrumentsData } from "./centralReducers/centralEquipments.reducer";
import { centralComplaintsData } from "./centralReducers/centralComplaints.reducer";




// without persisting state
// const rootReducer = combineReducers({
//   authentication,
//   users,
//   alert,
//   hospitals,
//   patients,
//   chatBox,
//   documentsPopup,
//   groupInfoPopup,
//   config,
//   homeState,
//   cacheStorage,
//   socketConnectivity,
//   //new config state
// });
// export default rootReducer



// with persisting state
const persistConfig = {
  key: 'root',
  storage,
  // whitelist:["authentication",
  //   "users",
  //   "alert",
  //   "hospitals",
  //   "patients",
  //   "chatBox",
  //   "documentsPopup",
  //   "groupInfoPopup",
  //   "config",
  //   "cacheStorage",
  //   "socketConnectivity",
  //   "homeState"
  // ],
  blacklist: ["authentication",
    "users",
    "alert",
    "hospitals",
    "patients",
    "chatBox",
    "documentsPopup",
    "groupInfoPopup",
    "config",
    "cacheStorage",
    "socketConnectivity",
    "homeState",
    "checklistSummary",
    "pcScore",
    "centralCommonData",
    "booleanStates"
  ]
}
const hospitalsConfig = {
  key: 'hospitals',
  storage,
  whitelist: [
    'isFetchingHospitalList',
    'isHospitalActive',
    // 'hospitalList',
    'currentHospitalData',
    'isFetchingLastIndexAPIError',
    'isFetchingLastIndexAPIActive',
    'isFetchingLastIndexAPI',
    'lastIndexAPIResponse'
  ]
};
const patientsConfig = {
  key: 'patients',
  storage,
  whitelist: [
    // 'patients',
    'isFetchingPatientList',
    'patientListActive',
    'patientInfoAPIData',
    'patientInfo',
  ]
};
const usersConfig = {
  key: 'users',
  storage,
  whitelist: [
    // 'patients',
    'notificationId',
  ]
};

const homeStateConfig = {
  key: 'homeState',
  storage,
  whitelist: [
    'showChatBox',
    'showGeneralChatMessages',
    'showHospitalList',
    'mainTitle',
    // 'checkUpdatedBuild',
    'logoutAnchorEL',
    'noHospitalsFound',
    'loadingSpinnerText',
    'screenName',
    'activeTab'
  ]
};

const chatBoxConfig = {
  key: 'chatBox',
  storage,
  whitelist: [
    'isFetchingPatientChatMessages',
    'patientChatMessagesActive',
    'fetchingPatientChatMessagesError',
    'isFetchingGeneralChatMessages',
    'GeneralChatMessagesActive',
    // 'hasMoreMessages',
    'fetchingGeneralChatMessagesError',
    // 'patientMessages',
    'selectedMessage',
    // 'chatTemplates',
    // 'generalChatMessages',
  ]
};
const checklistConfig = {
  key: 'checklistSummary',
  storage,
  whitelist: [
  ]
};
const cacheStorageConfig = {
  key: 'cacheStorage',
  storage,
  whitelist: [
    "sendAgainList",
    "retryList",
    // "sendAgainListCount"
  ]
};
const pcScoreConfig={
  key: 'pcScore',
  storage,
  whitelist: []
}


const rootReducer = combineReducers({
  authentication,
  users: persistReducer(usersConfig, users),
  alert,
  // hospitals,
  hospitals: persistReducer(hospitalsConfig, hospitals),
  // patients,
  patients: persistReducer(patientsConfig, patients),
  // chatBox,
  chatBox: persistReducer(chatBoxConfig, chatBox),
  documentsPopup,
  groupInfoPopup,
  config,
  centralCommonData,
  centralPatients,
  centralReportingData,
  centralHospitalData,
  centralInstrumentsData,
  centralComplaintsData,
  booleanStates,
  // homeState,
  homeState: persistReducer(homeStateConfig, homeState),
  cacheStorage:persistReducer(cacheStorageConfig,cacheStorage),
  socketConnectivity,
  checklistSummary,
  pcScore
  //new config state
});
export default persistReducer(persistConfig, rootReducer);
