import { toast } from "react-toastify";
import { centralComplaintsConstants } from "../../_constants";
import { centralGetComplaintsService } from "../../_services";
import { alertActions } from "../alert.actions";
import { configActions } from "../config.actions";

function getCentralComplaintsList(params) {
    return (dispatch,getState) => {
      dispatch(request());
      dispatch(configActions.showSpinningLoader());
      centralGetComplaintsService(params).then(
          (res) => {
            dispatch(success(res));
            dispatch(configActions.hideSpinningLoader());
        // !!res?.description && toast.success(res.description)
  
          },
          (error) => {
        toast.error(error.description)
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
        dispatch(configActions.hideSpinningLoader());
    };
  
    function request() {
      return {
        type: centralComplaintsConstants.FETCH_COMPLAINTS_DATA_REQUESTING,
      };
    }
    function success(res) {
      return {
        type: centralComplaintsConstants.CENTRAL_COMPLAINTS_DATA_FETCHED,
        listData: res.data,
      };
    }
    function failure(error) {
      return { type: centralComplaintsConstants.CENTRAL_COMPLAINTS_DATA_FETCHED, error };
    }
  }

export const centralComplaintsActions = {
    getCentralComplaintsList,
};
