import React, { useEffect, useState } from "react";
import "./centralSelect.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, FormControl, FormHelperText, MenuItem, OutlinedInput, Select } from "@mui/material";

export const CentralSelect = (props) => {
  let {
    value="",
    onChange=()=>{},
    children,
    options = [],
    placeholder = 'Select',
    label,
    jsonDetails= '',
    name = '',
    isMultiSelect = false,
    handleGlobalChanges=()=>{},
    error = false, disabled=false, helperText ='', width = '47%'
  } = props;
  const [dynamicValue, setDynamicValue] = useState(isMultiSelect ? jsonDetails && jsonDetails.value : '')

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: {width},
        backgroundColor: "#F6F6F6",
        border: " 1px solid #EBEBEB",
        fontSize: "12px",
        maxWidth:'350px',
      },
    },
  };
  
  useEffect(() => {
    if (!jsonDetails && ((options && options?.length === 0) || value === '')) {
      setDynamicValue(isMultiSelect ? [] : '');
    }
  }, [options, value]);

  const handleOnChange = (event) =>{
    if(isMultiSelect){
      const {
        target: { value },
      } = event;
      setDynamicValue(typeof value === 'string' ? value.split(',') : value,)
      handleGlobalChanges(jsonDetails &&jsonDetails?.postKey)
      onChange(event)
    }
    else{
      setDynamicValue(event.target.value)
      handleGlobalChanges(jsonDetails &&jsonDetails?.postKey)
      onChange(event)
    }
  }

const getValueToDisplay = () => {
  if (jsonDetails && jsonDetails?.value) {
    if (isMultiSelect) {
      const arr =  jsonDetails?.value?.length > 0 ? jsonDetails?.value : [];
      return arr
    } else {
      const optionsValues = options.map(option => option.value);
      const lowercaseJsonValue = jsonDetails?.value?.toLowerCase();
      const matchedValue = optionsValues.find(element => element?.toLowerCase() === lowercaseJsonValue);
      return matchedValue || '';
    }
  }
  return '';
};
  
  return (
    <FormControl
      sx={{ width: { width }, maxWidth: "350px" ,marginTop:'20px'}}
      variant="outlined"
      disabled={disabled || !!(jsonDetails && jsonDetails?.disable)}
      size="small"
      margin="dense"
      placeholder={placeholder}
      name= {name || (jsonDetails && jsonDetails?.key)}

    >
      <FormHelperText id="select-field-label" className="selectFieldLabel">
        {label || (jsonDetails &&jsonDetails?.label)}
      </FormHelperText>
      <Select
        labelId = 'select-field-label'
        value={ value || dynamicValue || getValueToDisplay() || (isMultiSelect?[]:'') || ''}
        name= {name || (isMultiSelect? (jsonDetails && jsonDetails?.postKey) + '-isMultiSelect': (jsonDetails && jsonDetails?.postKey))}
        onChange={handleOnChange}
        placeholder={placeholder}
        error={error}
        multiple={isMultiSelect}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        displayEmpty
        renderValue={isMultiSelect ? (selected) => selected.filter(item => item).join(', ') : null}
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={()=><SelectArrow disabled={disabled || !!(jsonDetails && jsonDetails?.disable)}/>
      }
        sx={{
            '& .MuiSelect-select .notranslate::after': placeholder
                ? {
                    content: `"${placeholder}"`,
                    opacity: 0.42,
                  }
                : {},
                height:'30px',fontSize:'12px', color:'#393939', lineHeight:'18px'
        }}
      >
        {/* <MenuItem value="" disabled className="selectMenuItemsStyle" hidden>Select</MenuItem> */}
            {options.length>0? options?.map(item => {
              const isSelected = isMultiSelect ? dynamicValue.includes(item.value):false;
              return <MenuItem key={item.value} value={item.value} className="selectMenuItemsStyle" sx={{ '&.Mui-selected': !isMultiSelect ? { backgroundColor: '#3476CD !important' , color:'#fff' }:{}}} >
                {isMultiSelect && (
                <>
                  <Checkbox checked={isSelected} />
                  {item.value}
                </>
              )}
              {!isMultiSelect && item.label}
              </MenuItem>
            }):''}
        {children}
      </Select>
      {error && helperText ? (
        <FormHelperText error id="" className="selectFieldError">
          {helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </FormControl>
  );
};

const SelectArrow = ({disabled, ...rest}) => <KeyboardArrowDownIcon  {...rest}  sx={{ color: disabled ? 'lightgray !important' : '#3D7CC9!important',right:'3px!important',top:'unset!important' }}  />

