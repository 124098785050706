import { toast } from "react-toastify";
import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let centralBaseUrl = process.env.REACT_APP_CENTRAL_BASE_URL;

export function centralGetComplaintsService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${centralBaseUrl}/fetch-complaint?appId=${params.appId}`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => res)
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
}


export async function raiseComplaints(params) {
  
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const paramsData =  {
    ...params,
  }

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...paramsData }),
  };
  return fetch(
    `${centralBaseUrl}/raise-complaint`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response ;
    });
}
export async function updateComplaintsService(params) {
  
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const paramsData =  {
    ...params,
  }

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...paramsData }),
  };
  return fetch(
    `${centralBaseUrl}/update-complaint`,
    requestOptions
  )
    .then(handleResponse)
    .then(res => res)
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response ;
    });
}


