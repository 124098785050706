
export const jsonConstants = [
    {
        "status": "Success",
        "description": " CommonData values for deCentralisationScheme",
        "result": {
            "_id": "0otsccnXm1p",
            "_created_at": 1714219571416,
            "data": [
                {
                    "title": "Appointment Details",
                    "key": "app_details",
                    "details": [
                        {
                            "label": "Hospital Name",
                            "key": "hosp_name",
                            "getKey": "hospitalName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "modalDetails": {
                                "header": "",
                                "description": ""
                            },
                            "options": [],
                            "class": "",
                            "disable": false
                        },
                        {
                            "label": "Patient Name",
                            "key": "patient_name",
                            "getKey": "patientName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Disease",
                            "key": "disease",
                            "getKey": "patientDisease.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Doctor Name",
                            "key": "doctor_name",
                            "getKey": "doctorName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "MOP",
                            "key": "mode_of_payment",
                            "getKey": "payment_mode",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only-style",
                            "disable": false,
                            "class": "green"
                        },
                        {
                            "label": "Surgery Call",
                            "key": "okForSurgery",
                            "getKey": "surgeryStatusIns.surgeryStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "ok-surgery",
                            "disable": false
                        },
                        {
                            "label": "Reimbursement Call Status",
                            "key": "call_status",
                            "getKey": "reimCallStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Date and Time",
                            "key": "appointment_date_time",
                            "getKey": "appointment_start_time",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false,
                            "format": "DD MMM, hh:mm A"
                        },
                        {
                            "label": "BD Name",
                            "key": "bd_name",
                            "getKey": "bd_name",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "IPD Posted At",
                            "key": "ipd_date_time",
                            "getKey": "ipdCreatedAt",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false,
                            "isUTC": true,
                            "format": "DD MMM, hh:mm A"
                        },
                        {
                            "label": "OT Slots(By Hospital)",
                            "key": "otSlots_hospital",
                            "getKey": "otSlots_hospital",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Cab boarding Time",
                            "key": "actualPickupTime",
                            "getKey": "actualPickupTime.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Expected Pt Arrival Time",
                            "key": "expectedPtArrivalTime",
                            "getKey": "expectedPatientArrivalSlots.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Admission Time",
                            "key": "admissionTime",
                            "getKey": "admissionTime",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Room Eligibility",
                            "key": "roomEligibility",
                            "getKey": "insuranceRoomType.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Tentative Amount",
                            "key": "tentativeAmount",
                            "getKey": "TentativeAmount",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Pre-Auth Status",
                            "key": "preAuth_status",
                            "getKey": "preAuthStatus",
                            "postKey": "preAuthStatus",
                            "value": "",
                            "functionType": "text-read-only",
                            "options": [
                                {
                                    "label": "Sent To TPA",
                                    "value": "Sent To TPA"
                                }
                            ],
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "Admission",
                    "key": "admission_details",
                    "details": [
                        {
                            "label": "Room Type (Insurance)",
                            "key": "room_type_insurance",
                            "getKey": "insuranceRoomType.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Total Collectible at Admission",
                            "key": "amount_collectible_admission",
                            "getKey": "proposedBreakdown.total",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Surgery Call (Cash Cases)",
                            "key": "surgeryStatusIns",
                            "getKey": "surgeryStatusIns.surgeryStatus",
                            "postKey": "surgeryStatusIns",
                            "value": "",
                            "functionType": "input-dropDown",
                            "isHideField": false,
                            "options": [
                                {
                                    "label": "Pre-Sx financially cleared",
                                    "value": "Pre-Sx financially cleared"
                                },
                                {
                                    "label": "Admission Collection pending",
                                    "value": "Admission Collection pending"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Actual room admission time",
                            "key": "admittedToRoom",
                            "getKey": "admittedToRoom.value",
                            "postKey": "admittedToRoom",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual Arrival Time",
                            "key": "arrival_time",
                            "getKey": "patient_admission_time.value",
                            "postKey": "patient_admission_time",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Room Type",
                            "key": "room_type",
                            "getKey": "hospChatTemplateDetails.roomType.value",
                            "postKey": "roomTypeHos",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Private",
                                    "value": "Private"
                                },
                                {
                                    "label": "Semi-Private",
                                    "value": "Semi-Private"
                                },
                                {
                                    "label": "Triple Sharing",
                                    "value": "Triple Sharing"
                                },
                                {
                                    "label": "General Ward",
                                    "value": "General Ward"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Room Number",
                            "key": "hospitalRoom",
                            "getKey": "hospitalRoom",
                            "postKey": "hospitalRoom",
                            "value": "",
                            "functionType": "input-text",
                            "disable": false,
                        },
                        {
                            "label": "Samples Collected",
                            "key": "samplesCollected ",
                            "getKey": "sampleCollectionStatus.value",
                            "postKey": "sampleCollectionStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Attendant",
                            "key": "attendant",
                            "getKey": "attendant.value",
                            "postKey": "attendant",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Total Collected at Admission",
                            "key": "amount_collected_admission",
                            "getKey": "totalCollectedOps.value",
                            "postKey": "totalCollectedOps",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false,
                        },
                        {
                            "label": "Collection Proof",
                            "key": "extraCashProof",
                            "getKey": "extraCashProof",
                            "postKey": "extraCashProof",
                            "value": "",
                            "functionType": "upload-file",
                            "disable": false
                        },
                        {
                            "label": "Upload Room Picture",
                            "key": "roomImages",
                            "getKey": "roomImages",
                            "postKey": "roomImages",
                            "value": "",
                            "functionType": "upload-images",
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "OT Details",
                    "key": "ot_details",
                    "details": [
                        {
                            "label": "Extra Test",
                            "key": "extra_test",
                            "getKey": "extraTest.value",
                            "postKey": "extraTest",
                            "value": "",
                            "functionType": "input-text",
                            "disable": false
                        },
                        {
                            "label": "OT Date & Time(Central OPS)",
                            "key": "ot_central_date_time",
                            "getKey": "otTimeOps.value",
                            "postKey": "otTimeOps",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual OT start time",
                            "key": "actual_ot_start_time",
                            "getKey": "actualOtStartTime.value",
                            "postKey": "actualOtStartTime",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual OT end time",
                            "key": "actual_ot_end_time",
                            "getKey": "actualOtEndTime.value",
                            "postKey": "actualOtEndTime",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Reports Status",
                            "key": "reports",
                            "getKey": "reports.value",
                            "postKey": "reports",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Received (In)",
                                    "value": "Received (In)"
                                },
                                {
                                    "label": "Received (O)",
                                    "value": "Received (O)"
                                },
                                {
                                    "label": "Not Received",
                                    "value": "Not Received"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "PAC",
                            "key": "pac",
                            "getKey": "hospitalPACStatus.value",
                            "postKey": "hospitalPACStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Pending",
                                    "value": "Pending"
                                },
                                {
                                    "label": "Cleared",
                                    "value": "Cleared"
                                },
                                {
                                    "label": "Not Cleared",
                                    "value": "Not Cleared"
                                },
                                {
                                    "label": "Extra Test Suggested",
                                    "value": "Extra Test Suggested"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Anesthetics Available",
                            "key": "anestheticsAvailable",
                            "getKey": "anestheticAvailability.value",
                            "postKey": "anestheticAvailability",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "In-House",
                                    "value": "In-House"
                                },
                                {
                                    "label": "Outside",
                                    "value": "Outside"
                                },
                                {
                                    "label": "Not Available",
                                    "value": "Not Available"
                                },
                            ],
                            "disable": false
                        },
                        {
                            "label": "Equipment Status",
                            "key": "equipment",
                            "getKey": "equipmentsStatus.value",
                            "postKey": "equipmentsStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Arranged (In)",
                                    "value": "Arranged (In)"
                                },
                                {
                                    "label": "Not Arranged",
                                    "value": "Not Arranged"
                                },
                                {
                                    "label": "Arranged (Out)",
                                    "value": "Arranged (Out)"
                                },
                            ],
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "Billing",
                    "key": "billing_details",
                    "details": [
                        {
                            "label": "Collection Gap",
                            "key": "collection_gap",
                            "getKey": "collectionGap",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only-style",
                            "disable": false,
                            "class": "red"
                        },
                        {
                            "label": "Advance Collected at GHV",
                            "key": "amount_collected_at_ghv",
                            "getKey": "advanceCollectedByBD.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Extra Cash Proposed (Insurance)",
                            "key": "extra_cash_insurance",
                            "getKey": "extraCashProposed.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PCCSC Proposed Amount (from Insurance)",
                            "key": "proposed_amount",
                            "getKey": "pccscProposed.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Waived off (insurance)",
                            "key": "waivedOff_insurance",
                            "getKey": "extraCashWavedOff.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Waived off Reasons (Insurance)",
                            "key": "waivedOff_reasons",
                            "getKey": "waiveOffReasons.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "modal-read-only",
                            "modalDetails": {
                                "header": "Waived off Reasons (Insurance)",
                                "description": ""
                            },
                            "disable": false
                        },
                        {
                            "label": "Extra Cash Applicable (Insurance)",
                            "key": "extra_case_applicable_insurance",
                            "getKey": "extraCashApplicable.extraCashApplicable",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PCCSC Applicable Amount",
                            "key": "applicable_amount",
                            "getKey": "pccscApplicable",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Proposed Package Amount (Cash cases)",
                            "key": "proposedPackageAmount",
                            "getKey": "proposedPackageAmount.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "OT Notes Status",
                            "key": "otNotesOverallStatus",
                            "getKey": "otNotesOverallStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "DS Status",
                            "key": "dischargeSummaryStatus",
                            "getKey": "dischargeSummaryStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Final Bill amount by Insurance",
                            "key": "final_bill_amount",
                            "getKey": "insFinalBillAmt.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Final Approval Amount",
                            "key": "final_approval_amount",
                            "getKey": "finalBillAmount",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Other Pristyn Payments",
                            "key": "other_payments",
                            "getKey": "CxEMIAmount.value",
                            "postKey": "CxEMIAmount",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Extra Test Required",
                            "key": "extraChargeReasons",
                            "getKey": "extraChargeReasons",
                            "postKey": "extraChargeReasons",
                            "value": "",
                            "functionType": "multi-select-dropDown",
                            "options": [
                                {
                                    "label": "No Extra Test",
                                    "value": "No Extra Test"
                                },
                                {
                                    "label": "Protection Kit Charges",
                                    "value": "Protection Kit Charges"
                                },
                                {
                                    "label": "Blood Transfusion + Procurement Charges",
                                    "value": "Blood Transfusion + Procurement Charges"
                                },
                                {
                                    "label": "MRI with Contrast Charges",
                                    "value": "MRI with Contrast Charges"
                                },
                                {
                                    "label": "Normal MRI Charges",
                                    "value": "Normal MRI Charges"
                                },
                                {
                                    "label": "CT Scan Charges",
                                    "value": "CT Scan Charges"
                                },
                                {
                                    "label": "2D Echo",
                                    "value": "2D Echo"
                                },
                                {
                                    "label": "Colonoscopy and Endoscopy",
                                    "value": "Colonoscopy and Endoscopy"
                                },
                                {
                                    "label": "Doppler Test",
                                    "value": "Doppler Test"
                                },
                                {
                                    "label": "Biopsy Charges (Small, Medium, Large)",
                                    "value": "Biopsy Charges (Small, Medium, Large)"
                                },
                                {
                                    "label": "Endoscopy",
                                    "value": "Endoscopy"
                                },
                                {
                                    "label": "HBSAG (Rapid)",
                                    "value": "HBSAG (Rapid)"
                                },
                                {
                                    "label": "HIV Test",
                                    "value": "HIV Test"
                                },
                            ],
                            "disable": false
                        },
                        {
                            "label": "Extra Charge Collected",
                            "key": "extra_charge_collected",
                            "getKey": "extraChargeCollected.value",
                            "postKey": "extraChargeCollected",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Reason for difference in Package Amount (Cash cases)",
                            "key": "different_package_amount_reasons",
                            "getKey": "reasonForDifferencePackageAmount.value",
                            "postKey": "reasonForDifferencePackageAmount",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Package Changed by BD",
                                    "value": "Package Changed by BD"
                                },
                                {
                                    "label": "Extra Stay Charges",
                                    "value": "Extra stay charges"
                                },
                                {
                                    "label": "Surgery Change",
                                    "value": "Surgery Change"
                                },
                                {
                                    "label": "Conservative Case",
                                    "value": "Conservative Case"
                                },
                                {
                                    "label": "Patient paid extra by mistake",
                                    "value": "Patient paid extra by mistake"
                                },
                                {
                                    "label": "Patient paying Less due to infra issue/ hospital issue",
                                    "value": "Patient paying Less due to infra issue/ hospital issue"
                                },
                                {
                                    "label": "Not Applicable",
                                    "value": "Not Applicable"
                                }
                            ],
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "Discharge",
                    "key": "discharge_details",
                    "details": [
                        {
                            "label": "Total Collectible at Discharge",
                            "key": "total_collectible_at_discharge",
                            "getKey": "applicableBreakdown.total",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PDS document",
                            "key": "pds_document",
                            "getKey": "pds_document",
                            "postKey": "",
                            "value": "",
                            "functionType": "summary-modal",
                            "disable": false
                        },
                        {
                            "label": "Fit For Discharge",
                            "key": "fitForDischarge",
                            "getKey": "patientFitForDischarge",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Final Bill Status",
                            "key": "finalBillStatus",
                            "getKey": "finalBillStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Extra Charge",
                            "key": "extraCharge.value",
                            "getKey": "extraCharge.value",
                            "postKey": "extraCharge",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Reason of Extra charge at Discharge",
                            "key": "reasonForExtraChargeAtDischarge",
                            "getKey": "reasonForExtraChargeAtDischarge",
                            "postKey": "reasonForExtraChargeAtDischarge",
                            "value": "",
                            "functionType": "multi-select-dropDown",
                            "options": [
                                {
                                    "label": "No Other Charges",
                                    "value": "No Other Charges"
                                },
                                {
                                    "label": "Protection Kit Charges",
                                    "value": "Protection Kit Charges"
                                },
                                {
                                    "label": "Blood Transfusion + Procurement Charges",
                                    "value": "Blood Transfusion + Procurement Charges"
                                },
                                {
                                    "label": "Extended OT charges",
                                    "value": "Extended OT charges"
                                },
                                {
                                    "label": "Support Staff Charges",
                                    "value": "Support Staff Charges"
                                },
                                {
                                    "label": "Anesthetics charges",
                                    "value": "Anesthetics charges"
                                },
                                {
                                    "label": "Extra Stay charges",
                                    "value": "Extra Stay charges"
                                },
                                {
                                    "label": "Extra doctor charges ( Cardiac, Gastro etc)",
                                    "value": "Extra doctor charges ( Cardiac, Gastro etc)"
                                },
                                {
                                    "label": "ICU charges",
                                    "value": "ICU charges"
                                },
                            ],
                            "disable": false
                        },
                        {
                            "label": "Discharge Cab Booking Status",
                            "key": "discharge_cab_booking_status",
                            "getKey": "cabBookingStatus.value",
                            "postKey": "cabBookingStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Actual Date of discharge (Date and time)",
                            "key": "date_of_discharge",
                            "getKey": "finalDateOfDischarge.value",
                            "postKey": "finalDateOfDischarge",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Total Collected at Discharge",
                            "key": "total_collected_at_discharge",
                            "getKey": "totalCollectedDisOps.value",
                            "postKey": "totalCollectedDisOps",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Collection Proof",
                            "key": "extraCashProof",
                            "getKey": "extraCashProof",
                            "postKey": "extraCashProof",
                            "value": "",
                            "functionType": "upload-file-discharge",
                            "disable": false
                        }
                    ]
                },
                {
                    "title": "Instruments",
                    "key": "instruments_details",
                    "details": []
                },
                {
                    "title": "Raise Complaints",
                    "key": "raiseComplaints",
                    "details": []
                },
                {
                    "title": "Patient Feedback",
                    "key": "patient_feedback",
                    "details": [
                        {
                            "label": "SubStatus",
                            "key": "subStatus",
                            "getKey": "patientFeedback.subStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Trigger Time",
                            "key": "triggerTime",
                            "getKey": "patientFeedback.triggerTime",
                            "postKey": "",
                            "value": "",
                            "format": "DD MMM, hh:mm A",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Source",
                            "key": "source",
                            "getKey": "patientFeedback.source",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Received At",
                            "key": "receivedAt",
                            "getKey": "patientFeedback.receivedAt",
                            "postKey": "",
                            "value": "",
                            "format": "DD MMM, hh:mm A",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                    ]
                }
            ],
            "name": "deCentralisationScheme",
            "decentralizeFilters": [
                {
                    "filterHeader": 'Status Tag',
                    "filterOptions": [{
                        "label": "Tentative IPD",
                        "value": "Tentative IPD"
                    },
                    {
                        "label": "Deficit",
                        "value": "Deficit"
                    },
                    {
                        "label": "Boarded the Cab",
                        "value": "Boarded the Cab"
                    },
                    {
                        "label": "Admitted",
                        "value": "Admitted"
                    },
                    {
                        "label": "PAC Cleared",
                        "value": "PAC Cleared"
                    },
                    {
                        "label": "PAC Not Cleared",
                        "value": "PAC Not Cleared"
                    },
                    {
                        "label": "Extra Test Suggested",
                        "value": "Extra Test Suggested"
                    },
                    {
                        "label": "Pre-Sx financially cleared",
                        "value": "Pre-Sx financially cleared"
                    },
                    {
                        "label": "In Surgery",
                        "value": "In Surgery"
                    },
                    {
                        "label": "Surgery Done",
                        "value": "Surgery Done"
                    },
                    {
                        "label": "Billing in progress",
                        "value": "Billing in progress"
                    },
                    {
                        "label": "Fit for Discharge",
                        "value": "Fit for Discharge"
                    },
                    {
                        "label": "Discharge processed",
                        "value": "Discharge processed"
                    },
                    {
                        "label": "Discharge in progress",
                        "value": "Discharge in progress"
                    },
                    {
                        "label": "Discharged",
                        "value": "Discharged"
                    },
                    {
                        "label": "Happy patients",
                        "value": "Happy patients"
                    }
                    ]
                },
                {
                    "filterHeader": 'Payment Mode',
                    "filterOptions": [
                        {
                            "value": 'Cash',
                            "label": 'Cash',

                        },
                        {
                            "value": 'Cashless',
                            "label": 'Cashless',

                        },
                        {
                            "value": 'Copay - Cashless',
                            "label": 'Copay - Cashless',

                        },
                        {
                            "value": 'Reimbursement',
                            "label": 'Reimbursement',
                        }
                    ]
                },
                {
                    "filterHeader": 'Surgery Call',
                    "filterOptions": [
                        {
                            "value": 'Ok for Surgery',
                            "label": 'Ok for Surgery',

                        },
                        {
                            "label": "Pre-Sx financially cleared",
                            "value": "Pre-Sx financially cleared"
                        },
                        {
                            "value": 'Documents Pending',
                            "label": 'Documents Pending',

                        },
                        {
                            "value": 'Not Ok For Surgery',
                            "label": 'Not Ok For Surgery',

                        },

                    ]
                }
            ]
        }
    }
]


export const arr = [
    {
        label: 'Admission Delay',
        value: 'Admission Delay',
        optionArray: [
            { value: 'Long Waiting Queue', label: 'Long Waiting Queue', optionArr: [] },
            { value: 'Hospital Not Aware About Arrival', label: 'Hospital Not Aware About Arrival', optionArr: [] },
            { value: 'DA Pending', label: 'DA Pending', optionArr: [] },
            { value: 'Initial Approval Awaited', label: 'Initial Approval Awaited', optionArr: [] },
            { value: 'Hospital Staff Unavailable', label: 'Hospital Staff Unavailable', optionArr: [] },
            { value: 'Delay In Documentation', label: 'Delay In Documentation', optionArr: [] },
            { value: 'Hospital Changed', label: 'Hospital Changed', optionArr: [] },
            { value: 'Delayed Cab', label: 'Delayed Cab', optionArr: [] }
        ]
    },
    {
        label: 'OT Delay',
        value: 'OT Delay',
        optionArray: [
            { label: 'Doctor Delay', value: 'Doctor Delay', optionArr: [] },
            { label: 'OT Not Available', value: 'OT Not Available', optionArr: [] },
            { label: 'Equipment Issue', value: 'Equipment Issue', optionArr: [] },
            { label: 'Initial Approval Awaited', value: 'Initial Approval Awaited', optionArr: [] },
            { label: 'Initial Approval Rejected', value: 'Initial Approval Rejected', optionArr: [] },
            { label: 'Pre-Test Pending', value: 'Pre-Test Pending', optionArr: [] },
            { label: 'Test Report Awaited', value: 'Test Report Awaited', optionArr: [] },
            { label: 'Attendant Information Not Provided', value: 'Attendant Information Not Provided', optionArr: [] }
        ]
    },
    {
        label: 'Room',
        value: 'Room',
        optionArray: [
            {
                label: 'Room Allotment Delay',
                value: 'Room Allotment Delay',
                optionArr: [
                    { label: 'Other Patient In The Room', value: 'Other Patient In The Room' }
                ]
            },
            {
                label: 'Washroom Related Concern',
                value: 'Washroom Related Concern',
                optionArr: [
                    { label: 'Washroom Not Clean', value: 'Washroom Not Clean' },
                    { label: 'Washroom Commode Broken/Not Cleaned', value: 'Washroom Commode Broken/Not Cleaned' },
                    { label: 'Washbasin Not Clean', value: 'Washbasin Not Clean' },
                    { label: 'Geezer/Flush Not Working', value: 'Geezer/Flush Not Working' }
                ]
            },
            {
                label: 'Furniture & Fixtures',
                value: 'Furniture & Fixtures',
                optionArr: [
                    { label: 'AC/Fan Not Working', value: 'AC/Fan Not Working' },
                    { label: 'Bed Mattress Not Clean', value: 'Bed Mattress Not Clean' },
                    { label: 'Attendant Bed Not Available', value: 'Attendant Bed Not Available' },
                    { label: 'AC Not Available', value: 'AC Not Available' },
                    { label: 'Water Leakage', value: 'Water Leakage' },
                    { label: 'Unavailability Of TV', value: 'Unavailability Of TV' },
                    { label: 'Room Light Concern', value: 'Room Light Concern' },
                    { label: 'Room Not Clean', value: 'Room Not Clean' }
                ]
            },
            {
                label: 'Private Room Issue',
                value: 'Private Room Issue',
                optionArr: [
                    { label: 'Availability Issue', value: 'Availability Issue' },
                    { label: 'Room Eligibility Issue', value: 'Room Eligibility Issue' }
                ]
            }
        ]
    },
    {
        label: 'Food',
        value: 'Food',
        optionArray: [
            {
                label: 'Food Not Provided',
                value: 'Food Not Provided',
                optionArr: [
                    { label: 'Attendant - Breakfast', value: 'Attendant - Breakfast' },
                    { label: 'Attendant - Dinner', value: 'Attendant - Dinner' },
                    { label: 'Patient - Breakfast', value: 'Patient - Breakfast' },
                    { label: 'Patient - Dinner', value: 'Patient - Dinner' }
                ]
            },
            {
                label: 'Food Delivered Late',
                value: 'Food Delivered Late',
                optionArr: [
                    { label: 'Breakfast', value: 'Breakfast' },
                    { label: 'Dinner', value: 'Dinner' }
                ]
            },
            {
                label: 'Food Quality Issue',
                value: 'Food Quality Issue',
                optionArr: [
                    { label: 'Tasteless/Spicy/Oily', value: 'Tasteless/Spicy/Oily' },
                    { label: 'Unhygienic', value: 'Unhygienic' },
                    { label: 'Stale/Burnt', value: 'Stale/Burnt' }
                ]
            },
            {
                label: 'Food Quantity Issue',
                value: 'Food Quantity Issue',
                optionArr: [
                    { label: 'Breakfast', value: 'Breakfast' },
                    { label: 'Dinner', value: 'Dinner' }
                ]
            }
        ]
    },
    {
        label: 'Cab',
        value: 'Cab',
        optionArray: [
            {
                label: 'Cab Delayed',
                value: 'Cab Delayed',
                optionArr: [
                    { label: 'Admission Cab Delayed', value: 'Admission Cab Delayed' },
                    { label: 'Discharge Cab Delayed', value: 'Discharge Cab Delayed' }
                ]
            },
            {
                label: 'Cab Related Issues',
                value: 'Cab Related Issues',
                optionArr: [
                    { label: 'Rude Driver', value: 'Rude Driver' },
                    { label: 'Abusive Driver', value: 'Abusive Driver' },
                    { label: 'Driver Asked For Cash Or Extra Amount', value: 'Driver Asked For Cash Or Extra Amount' },
                    { label: 'Dropped The Patient In Midway', value: 'Dropped The Patient In Midway' },
                    { label: 'AC Not Working', value: 'AC Not Working' },
                    { label: 'Seat Dirty', value: 'Seat Dirty' }
                ]
            }
        ]
    },
    {
        label: 'Doctor',
        value: 'Doctor',
        optionArray: [
            {
                label: 'Doctor Behaviour',
                value: 'Doctor Behaviour',
                optionArr: [
                    { label: 'Rude Behaviour', value: 'Rude Behaviour' },
                    { label: 'No Proper Response', value: 'No Proper Response' }
                ]
            },
            {
                label: 'Explanation & Examination',
                value: 'Explanation & Examination',
                optionArr: [
                    { label: 'Post Surgery Visit Not Done', value: 'Post Surgery Visit Not Done' },
                    { label: 'Post Discharge Instructions Not Provided', value: 'Post Discharge Instructions Not Provided' }
                ]
            }
        ]
    },
    {
        label: 'BD',
        value: 'BD',
        optionArray: [
            {
                label: 'Financial Miscommitment',
                value: 'Financial Miscommitment',
                optionArr: [
                    { label: 'Extra Charges Not Informed', value: 'Extra Charges Not Informed' },
                    { label: 'Riemb. Process Not Explained', value: 'Riemb. Process Not Explained' },
                    { label: 'Co-Pay Charges Not Informed', value: 'Co-Pay Charges Not Informed' },
                    { label: 'Post Discharge Medicines Not Informed', value: 'Post Discharge Medicines Not Informed' },
                    { label: 'Incorrect Package Informed', value: 'Incorrect Package Informed' },
                    { label: 'Miscommitment Regarding Food And Cab', value: 'Miscommitment Regarding Food And Cab' }
                ]
            },
            {
                label: 'Operational Miscommitment',
                value: 'Operational Miscommitment',
                optionArr: [
                    { label: 'Wrong Surgery Time', value: 'Wrong Surgery Time' },
                    { label: 'Wrong Discharge Time', value: 'Wrong Discharge Time' },
                    { label: 'Incorrect Room Eligibility', value: 'Incorrect Room Eligibility' },
                    { label: 'Regarding Insurance Approval', value: 'Regarding Insurance Approval' }
                ]
            },
            {
                label: 'Behaviour Issue',
                value: 'Behaviour Issue',
                optionArr: [
                    { label: 'Rude Behaviour', value: 'Rude Behaviour' },
                    { label: 'BD Not Responding', value: 'BD Not Responding' },
                    { label: 'BD Counseling Not Helpful', value: 'BD Counseling Not Helpful' }
                ]
            },
            {
                label: 'Others',
                value: 'Others',
                optionArr: [
                    { label: 'Delayed Arrival', value: 'Delayed Arrival' },
                    { label: 'Attendant Not There', value: 'Attendant Not There' },
                    { label: 'Not On NPO', value: 'Not On NPO' }
                ]
            }
        ]
    },
    {
        label: 'Staff',
        value: 'Staff',
        optionArray: [
            {
                label: 'Staff Behaviour',
                value: 'Staff Behaviour',
                optionArr: [
                    { label: 'Rude Behaviour With Patient', value: 'Rude Behaviour With Patient' },
                    { label: 'No Response', value: 'No Response' },
                    { label: 'Theft', value: 'Theft' },
                    { label: 'Staff Not Helpful', value: 'Staff Not Helpful' },
                    { label: 'Abusive', value: 'Abusive' }
                ]
            },
            {
                label: 'Untrained Staff',
                value: 'Untrained Staff',
                optionArr: [
                    { label: 'Unable To Insert Cannula', value: 'Unable To Insert Cannula' },
                    { label: 'Discharge Summary Not Explained', value: 'Discharge Summary Not Explained' },
                    { label: 'Not Able To Respond The Patient Concern Properly', value: 'Not Able To Respond The Patient Concern Properly' }
                ]
            }
        ]
    },
    {
        label: 'Discharge',
        value: 'Discharge',
        optionArray: [
            {
                label: 'Document Delay',
                value: 'Document Delay',
                optionArr: [
                    { label: 'OT Notes Delay', value: 'OT Notes Delay' },
                    { label: 'Delay In Sending To TPA', value: 'Delay In Sending To TPA' },
                    { label: 'Consumable Invoice Delay', value: 'Consumable Invoice Delay' },
                    { label: 'Bill Revision Delay', value: 'Bill Revision Delay' },
                    { label: 'Billing Staff Not Available', value: 'Billing Staff Not Available' },
                    { label: 'Final Bill Pending', value: 'Final Bill Pending' }
                ]
            },
            {
                label: 'Insurance Related',
                value: 'Insurance Related',
                optionArr: [
                    { label: 'Discharge Summary Delay', value: 'Discharge Summary Delay' },
                    { label: 'Final Approval Delay', value: 'Final Approval Delay' },
                    { label: 'Query Raised By TPA', value: 'Query Raised By TPA' },
                    { label: 'Final Approval Rejected', value: 'Final Approval Rejected' },
                    { label: 'Hospital Not Ready To Discharge On Internal', value: 'Hospital Not Ready To Discharge On Internal' }
                ]
            },
            {
                label: 'Extra Payment Issue',
                value: 'Extra Payment Issue',
                optionArr: [
                    { label: 'Patient Not Ready To Pay Extra Charges', value: 'Patient Not Ready To Pay Extra Charges' }
                ]
            },
            { label: 'Delay In Enach Process', value: 'Delay In Enach Process', optionArr: [] }
        ]
    }
];