import React, { Fragment, useEffect, useState } from "react";
import { CentralSelect } from "../CentralSelect/CentralSelect";
import { AVAILABILITY, PROVIDED_BY, UTILIZATION_STATUS } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import { centralEquipmentsActions } from "../../../_actions/centralActions/centralEquipments.actions";
import {
  centralAddOrDeleteInstrumentsService,
  centralUpdateInstrumentsService,
} from "../../../_services";
import { Divider, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CentralModal } from "../CentralModal/CentralModal";
import { toast } from "react-toastify";
import { CentralInputField } from "../CentralInputField/CentralInputField";
import CheckIcon from "@mui/icons-material/Check";
import "./centralEquipmentModal.css";

export const CentralInstructionsAccordion = () => {
  const [equipmentData, setEquipmentData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckButton, setIsCheckButton] = useState([]);

  const [instrumentData, setInstrumentData] = useState([]);
  const dispatch = useDispatch();
  let equipmentDetails = useSelector(
    (state) => state.centralInstrumentsData.listData
  );
  let reportingData = useSelector((state) => state.centralReportingData);
  let activePatientId = "";
//   useEffect(() => {
//     if (reportingData && reportingData?.activePatientId) {
//       activePatientId = reportingData.activePatientId;
//       dispatch(
//         centralEquipmentsActions.getCentralInstrumentsList({
//           appId: activePatientId,
//         })
//       );
//     }
//   }, []);

  useEffect(() => {
    if (equipmentDetails.length) {
      const instrumentsArr = equipmentDetails?.length >0 && equipmentDetails[0]?.instruments;
      if (instrumentsArr?.length) {
        setEquipmentData(instrumentsArr);
        if(instrumentsArr && instrumentsArr?.length){
            const inputData = [...instrumentsArr]?.reverse().map(item=>({quantity:item.quantity || "", itemSpecification: item.itemSpecification || ""}))
            setInstrumentData(inputData)
        }
      }
    } else {
      setEquipmentData([]);
    }
  }, [equipmentDetails]);

  const handleChange = (name, value, id, itemFromBackend) => {
    let appId = "";
    if (reportingData && reportingData?.activePatientId) {
      appId = reportingData.activePatientId;
    }
    let instrumentsFinalStatus = "commercial";
    const updatedEquipmentData = equipmentData.map((item) =>
      item._id === id ? { ...item, [name]: value } : item
    );

    const providedByFilledCount = updatedEquipmentData.filter(
      (item) => item.providedBy
    ).length;
    const allStatusArrivedAtHospital = updatedEquipmentData.every(
      (item) => item.status === "Arrived at Hospital"
    );
    const statusNotFilled = updatedEquipmentData.some((item) => !item.status);

    if (providedByFilledCount < updatedEquipmentData.length) {
      instrumentsFinalStatus = "city";
    } else if (providedByFilledCount === updatedEquipmentData.length - 1) {
      instrumentsFinalStatus = "commercial";
    } else if (statusNotFilled) {
      instrumentsFinalStatus =
        providedByFilledCount === updatedEquipmentData.length
          ? "commercial"
          : "city";
    } else if (allStatusArrivedAtHospital) {
      instrumentsFinalStatus = "closed";
    }
    let extraObj = {};
    if (name === "utilisationStatus" || name==="equipment" || name==="quantity" || name==='itemSpecification') {
      extraObj = {
        providedBy: itemFromBackend?.providedBy,
      };
    }
    const params = {
      appId: appId,
      id: id,
      [name]: value,
      availabilityTime: new Date().toISOString(),
      source: "central-dash",
      instrumentsFinalStatus: instrumentsFinalStatus,
      ...extraObj,
    };

    centralUpdateInstrumentsService(params)
      .then((response) => {
        if (response.message === "Success") {
          setEquipmentData((prevData) =>
            prevData.map((item) =>
              item._id === id ? { ...item, [name]: value } : item
            )
          );
          dispatch(
            centralEquipmentsActions.getCentralInstrumentsList({
              appId: appId,
            })
          );
          toast.success(`${name} updated successfully`);
        }
      })
      .catch((err) => {
        console.log("Update equipment error", err);
      });
  };

  const handleDeleteInstrument = (item) => {
    const {
      category = "",
      equipment = "",
      _id = "",
      quantity = "",
      source = "",
    } = item;
    let appId = "";
    if (reportingData && reportingData?.activePatientId) {
      appId = reportingData.activePatientId;
    }
    if (appId) {
      const params = {
        deleteInstrument: [
          {
            category: category,
            id: _id,
            quantity: quantity,
            equipment: equipment,
            source: source,
          },
        ],
        newInstruments: [],
        appId: appId,
      };
      centralAddOrDeleteInstrumentsService(params)
        .then((response) => {
          if (response.message === "Success") {
            toast.success(`Instrument Deleted Successfully`);
            setEquipmentData((prevData) =>
              prevData.filter((equipment) => equipment._id !== _id)
            );
            dispatch(
              centralEquipmentsActions.getCentralInstrumentsList({
                appId: appId,
              })
            );
          }
        })
        .catch((err) => {
          console.log("Update equipment error", err);
        });
    }
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleEndAdornmentClick = () => {};

  const handleInputChange = (name, value, index) => {
    setInstrumentData((prevAmounts) => {
      const updatedData = prevAmounts.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : { ...item }
      );

      return updatedData;
    });
  };

  return (
    <div>
      <CentralModal
        open={!!isModalOpen}
        isModalFooterShow={true}
        onSubmit={() => handleDeleteInstrument(isModalOpen)}
        onClose={handleModalClose}
        buttonTitle="Yes"
        cancelButtonTitle="No"
        modalTitle={""}
        isShowDivider={false}
        sx={{ minHeight: "135px" }}
        modalButton={<span></span>}
      >
        <div className="modalBodyDataContainer">
          Do you want to remove the Item ?
        </div>
      </CentralModal>
      {equipmentData && equipmentData.length > 0 ? (
        [...equipmentData].reverse().map((item, index) => (
          <Fragment key={item._id}>
            <div className="equipmentCountContainer">
              <span className="equipmentCountText">Item {index + 1}</span>
              <span className="crossButtonContainer">
                <IconButton onClick={() => setIsModalOpen(item)}>
                  <DeleteForeverIcon
                    sx={{ color: "#3476CD", height: "20px", width: "20px" }}
                  />
                </IconButton>
              </span>
            </div>
            <Divider sx={{ marginBottom: "10px" }} />
            <div className="textReadOnlyRow">
              <span className="textReadOnlyLabel">Type</span>
              <span className="textReadOnlyValue">{item.type || "--"}</span>
            </div>
            <div className="textReadOnlyRow">
              <span className="textReadOnlyLabel">Name</span>
              <span className="textReadOnlyValue">{item.equipment || item.equipmentName || "--"}</span>
            </div>
            <div className="equipmentContentContainer equipmentContentContainer1">
              <CentralInputField
                value={instrumentData[index].itemSpecification || ""}
                label="Item Specification"
                handleOnChange={(event) =>
                  handleInputChange(
                    "itemSpecification",
                    event.target.value,
                    index,
                    item._id
                  )
                }
                maxLength={70}
                endAdornment={
                  instrumentData[index].itemSpecification &&
                  instrumentData[index].itemSpecification !=
                    item.itemSpecification ? (
                    <CheckIcon
                      onClick={()=>handleChange("itemSpecification", instrumentData[index].itemSpecification, item._id, item)}
                      fontSize="small"
                      style={{ cursor: "pointer", color: "#3778CE" }}
                    />
                  ) : (
                    ""
                  )
                }
              />
              <CentralInputField
                value={instrumentData[index].quantity || ""}
                label="Quantity"
                handleOnChange={(event) =>
                  handleInputChange(
                    "quantity",
                    event.target.value,
                    index,
                    item._id
                  )
                }
                type={"number"}
                endAdornment={
                  instrumentData[index].quantity &&
                  instrumentData[index].quantity != item.quantity ? (
                    <CheckIcon
                      onClick={()=>handleChange("quantity", instrumentData[index].quantity, item._id,item)}
                      fontSize="small"
                      style={{ cursor: "pointer", color: "#3778CE" }}
                    />
                  ) : (
                    ""
                  )
                }
              />
              <CentralSelect
                value={item?.providedBy || ""}
                label="Provided By *"
                options={PROVIDED_BY}
                onChange={(event) =>
                  handleChange("providedBy", event.target.value, item._id)
                }
              />
              <CentralSelect
                value={item?.status || ""}
                disabled={!item?.providedBy}
                label="Availability *"
                options={AVAILABILITY}
                onChange={(event) =>
                  handleChange("status", event.target.value, item._id)
                }
              />
              <CentralSelect
                value={item?.utilisationStatus || ""}
                disabled={!item?.providedBy}
                label="Utilization Status"
                options={UTILIZATION_STATUS}
                onChange={(event) =>
                  handleChange(
                    "utilisationStatus",
                    event.target.value,
                    item._id,
                    item
                  )
                }
              />
            </div>
          </Fragment>
        ))
      ) : (
        <span className="emptyEquipmentData">No Item available</span>
      )}
    </div>
  );
};
