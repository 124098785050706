import React, { useEffect, useState } from 'react'
import './centralEquipmentModal.css'
import { CentralModal } from '../CentralModal/CentralModal';
import { CentralSelect } from '../CentralSelect/CentralSelect';
import { CentralInputField } from '../CentralInputField/CentralInputField';
import { AVAILABILITY, EQUIPMENT_CATEGORY, EQUIPMENT_TYPE, PROVIDED_BY, UTILIZATION_STATUS } from './constant';
import { useDispatch, useSelector } from 'react-redux';
import { centralAddOrDeleteInstrumentsService } from '../../../_services';
import { getTokenFromLocal } from '../../../_helpers/handleResponseMiddleware';
import { centralEquipmentsActions } from '../../../_actions/centralActions/centralEquipments.actions';
import { toast } from 'react-toastify';

export const CentralEquipmentModal = React.memo((props) => {
    const { open, onClose, onAddMore, onOpen, instrumentsOptions, equipmentType='' } = props
    const reportingData = useSelector(state=>state.centralReportingData);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        equipmentCategory: '',
        equipmentName: '',
        equipmentOtherCategory: '',
        equipmentOtherName: '',
        quantity: '',
        providedBy: '',
        availability: '',
        utilizationStatus: '',
        instrumentType:'',
        itemSpecification:''
      });

    const [instrumentsDetails, setInstrumentsDetails] = useState({
        equipmentCategory: [],
        equipmentName: [],
      });
    const [isOtherCategory, setIsOtherCategory] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

      // useEffect(() => {
      //   if(equipmentType==='native' && instrumentsOptions.length){
      //       const uniqueInstrumentsOptions = [...new Map(instrumentsOptions.map(item => [item.Equipment, item])).values()];
      //       let category = uniqueInstrumentsOptions.map(item => ({ 'label': item.Equipment, 'value': item.Equipment }));
      //       category.unshift({ label: 'Others', value: 'Others' });
      //       setInstrumentsDetails(prev=>({...prev, equipmentCategory:category, equipmentName:[]}))
      //   }
      //   else if(equipmentType==='web' && instrumentsOptions){
      //       if(reportingData && reportingData?.reportingData?.data?.length){
      //           const disease = reportingData?.reportingData?.data[0]?.disease
      //           const diseaseList = Object.keys(instrumentsOptions)
                
      //           let diseaseObject = instrumentsOptions['default'] || {};
      //           for(let i=0; i<diseaseList.length;i++){
      //             if(diseaseList[i].toLowerCase()===disease.toLowerCase()){
      //               diseaseObject = instrumentsOptions[diseaseList[i]]
      //               break;
      //             }
      //           }
      //           let category = Object.keys(diseaseObject).map(item=>({label:item,value:item}))
      //           category.unshift({ label: 'Others', value: 'Others' });
      //           setInstrumentsDetails(prev=>({...prev, equipmentCategory:category, equipmentName:[]}))
                
      //       }
      //   }
      //   else {
      //     setInstrumentsDetails(prev => ({
      //         ...prev,
      //         equipmentCategory: [{ label: 'Others', value: 'Others' }],
      //         equipmentName: []
      //     }));
      // }
      // }, [instrumentsOptions])

      useEffect(() => {
        if(formData.instrumentType){
          if(reportingData && reportingData?.reportingData?.data?.length){
            const category = reportingData?.reportingData?.data[0]?.category
            const equipmentCat = EQUIPMENT_CATEGORY[formData.instrumentType][category] || []
            if(equipmentCat && equipmentCat?.length){
              setInstrumentsDetails(prev => ({
                ...prev,
                equipmentCategory: [
                    { label: 'Others', value: 'Others' },
                    ...equipmentCat.map(item => ({ label: item, value: item }))
                ],
                equipmentName: [],
            }));
            }else {
              setInstrumentsDetails(prev => ({
                ...prev,
                equipmentCategory: [{ label: 'Others', value: 'Others' }],
                equipmentName: [],
            }));
          }
          }
        }
      }, [formData.instrumentType])
      
      const handleChange = (name, value) => {
        // if(name==='equipmentCategory' && equipmentType ==='native' && instrumentsOptions.length>0){
        //     if(value !=='Others'){
        //     setIsOtherCategory(false)
            
        //     const uniqueInstrumentsOptions = [...new Map(instrumentsOptions.map(item => [item.Equipment, item])).values()];
        //     const equipmentName  = uniqueInstrumentsOptions.filter(item=>item.Equipment===value)[0]?.SubCategory
            
        //     if(equipmentName.length>0){
        //         const nameDetails = equipmentName.map(item=>({'label':item, 'value':item}))
        //         setInstrumentsDetails(prev=>({...prev, equipmentName:nameDetails}))
        //     }
        // }
        // else {
        //     setIsOtherCategory(true)
        //     setInstrumentsDetails(prev=>({...prev, equipmentName:[]}))
        //     setFormData(prev=>({...prev,
        //         equipmentName: '',
        //       }))
        // }
        // }
        // else if(name==='equipmentCategory' && equipmentType ==='web'){
        //     if(value !=='Others'){
        //         setIsOtherCategory(false)
        //         if(reportingData && reportingData?.reportingData?.data?.length){
        //             const disease = reportingData?.reportingData?.data[0]?.disease
        //             const diseaseList = Object.keys(instrumentsOptions)
        //             let diseaseObject = instrumentsOptions['default'] || {};
                    
        //         for(let i=0; i<diseaseList.length;i++){
        //           if(diseaseList[i].toLowerCase()===disease.toLowerCase()){
        //             diseaseObject = instrumentsOptions[diseaseList[i]]
        //             break;
        //           }
        //         }
        //         const categoryList = Object.keys(diseaseObject)
        //         for(let i=0; i<categoryList.length;i++){
        //           if(categoryList[i].toLowerCase()===value.toLowerCase()){
        //             const subCat = diseaseObject[categoryList[i]]
        //             if(subCat){
        //                 const allSubCat = Object.keys(subCat).map(item=>({label:item, value:item}))
        //                 setInstrumentsDetails(prev=>({...prev, equipmentName:allSubCat}))
        //             }
        //             break;
        //           }
        //         }
        //         }
        //     }
        //     else{
        //         setIsOtherCategory(true)
        //         setInstrumentsDetails(prev=>({...prev, equipmentName:[]}))
        //         setFormData(prev=>({...prev,
        //         equipmentName: '',
        //       }))
        //     }
        // }
        if(name==='instrumentType'){
          setIsOtherCategory(false)
          setFormData(prev => ({
            ...prev,
            equipmentCategory: '',
            equipmentOtherCategory: '',
        }));
        }
        if(name==='equipmentCategory'){
          if(value !=='Others'){
            setIsOtherCategory(false)
          }
          else{
            setIsOtherCategory(true)
          }
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const handleOnClose = () =>{
        onClose();
        setFormData(prev=>({...prev,
            equipmentCategory: '',
            equipmentName: '',
            equipmentOtherCategory: '',
            equipmentOtherName: '',
            quantity: '',
            instrumentType: '',
            itemSpecification: '',
            providedBy: '',
            availability: '',
            utilizationStatus: ''
          }))
          setIsOtherCategory(false)
    }

    const handleOnSubmit = () => {
        let user = getTokenFromLocal();
    let userName = user ? user.username || user?.name : null;
    let appId = ''
    if(reportingData && reportingData.activePatientId){
            appId = reportingData.activePatientId
            const params = {"deleteInstrument":[],
                "newInstruments":[
                    {
                      "equipment":formData.equipmentOtherCategory || null,
                      "equipmentName":formData.equipmentCategory,
                      "itemSpecification":formData.itemSpecification,
                      "providedBy" : formData.providedBy, 
                      "status" : formData.availability,
                      "type":formData.instrumentType,
                      "quantity":formData.quantity,
                      "utilisationStatus":formData.utilizationStatus,
                      "createdAt":`${new Date().toISOString()}`,
                      "userName":userName,
                      "source":"hosChatApp"
                    }
                ],
                "appId":appId,"createdAt":`${new Date().toISOString()}`}
            
            centralAddOrDeleteInstrumentsService(params)
            .then(response=>{
                if(response.message ==='Success'){
                  toast.success('Instrument Added Successfully')
                    dispatch(centralEquipmentsActions.getCentralInstrumentsList({"appId":appId}))
                }
            })
            .catch(err=>{
                console.log('Add new equipment error', err);
            })
            onAddMore();
            handleOnClose();
        }
        
      };
    
useEffect(() => {
    const categoryCondition = formData.equipmentCategory !== 'Others' ? formData.equipmentCategory : formData.equipmentOtherCategory;
    const nameCondition = instrumentsDetails.equipmentName.length > 0 ? formData.equipmentName || formData.equipmentOtherName : true;
    const quantityCondition = formData.quantity;

    if (categoryCondition && formData.instrumentType && formData.providedBy && formData.availability) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [formData, instrumentsDetails]);

  return (
    <CentralModal
      modalTitle="Add Item"
      open={open}
      onClose={handleOnClose}
      buttonTitle="Save"
      isShowDivider={(true)}
      isSubmitDisabled = {isSubmitDisabled}
      onSubmit={handleOnSubmit}
      isModalFooterShow={true}
      modalButton={
        <div className='addMoreButton' onClick={()=>onOpen()}>Add +</div>
      }
    >
      <div className='equipmentContentContainer'>
      <CentralSelect value={formData.instrumentType || ''} label="Type *" options={EQUIPMENT_TYPE} onChange={(event) => handleChange('instrumentType', event.target.value)}/>
          <CentralSelect value={formData.equipmentCategory || ''} disabled = {!formData?.instrumentType} label="Name *" options={instrumentsDetails.equipmentCategory || []} onChange={(event) => handleChange('equipmentCategory', event.target.value)} />
        {
          !isOtherCategory? <>
          <CentralInputField value={formData.itemSpecification || ''} label="Item Specification" handleOnChange={(event) => handleChange('itemSpecification', event.target.value)} maxLength = {70}/>
            {/* <CentralSelect value={formData.equipmentName || ''} label="Equipment Name" options={instrumentsDetails.equipmentName || []} onChange={(event) => handleChange('equipmentName', event.target.value)} /> */}
              </>
            :
            <>
                
                <CentralInputField value={formData.equipmentOtherCategory || ''} label="Other Name *" handleOnChange={(event) => handleChange('equipmentOtherCategory', event.target.value)} />
                <CentralInputField value={formData.itemSpecification || ''} label="Item Specification" handleOnChange={(event) => handleChange('itemSpecification', event.target.value)} maxLength = {70}/>
                {/* <CentralInputField value={formData.equipmentName || ''} label="Equipment Name" handleOnChange={(event) => handleChange('equipmentOtherName', event.target.value)} /> */}
            </>
        }
        <CentralInputField value={formData.quantity || ''} label="Quantity" handleOnChange={(event) => handleChange('quantity', event.target.value)} type = {"number"}/>
        <CentralSelect value={formData.providedBy || ''} label="Provided By *" options={PROVIDED_BY} onChange={(event) => handleChange('providedBy', event.target.value)}/>
        <CentralSelect value={formData.availability || ''} disabled = {!formData?.providedBy} label="Availability *" options={AVAILABILITY} onChange={(event) => handleChange('availability', event.target.value)}/>
        <CentralSelect value={formData.utilizationStatus || ''} disabled = {!formData?.providedBy} label="Utilization Status" options={UTILIZATION_STATUS} onChange={(event) => handleChange('utilizationStatus', event.target.value)}/>
      </div>
    </CentralModal>
  );
}
)