import { centralGetInitialState } from "../../_helpers/centralGetInitialState";
import { centralComplaintsConstants } from "../../_constants";

export function centralComplaintsData(
    state = centralGetInitialState("centralComplaintsData"),
    action
  ) {
    switch (action.type) {
      case centralComplaintsConstants.FETCH_COMPLAINTS_DATA_REQUESTING:
        return {
          ...state,
        };
      case centralComplaintsConstants.CENTRAL_COMPLAINTS_DATA_FETCHED:
          const { listData } = action;
        return {
          ...state,
          listData
        };
      case centralComplaintsConstants.COMPLAINTS_DATA_FAILED:
        return {
          ...state,
          complaintsDataFetchingError: action.error,
        };
      default:
        return state;
    }
  }
  