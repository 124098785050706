import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Typography,
  Collapse,
  TextField,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import { raiseComplaints, updateComplaintsService } from "../../../_services";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function CentralComplaintSection({ data , id, prefilledData=null}) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [expandedSections, setExpandedSections] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [remarks, setRemarks] = useState({});
  const navigate = useNavigate();
  let activeHospitalId = useSelector(state=>state.centralHospitalData.activeHospitalId) 

  const handleCategorySelection = (value) => {
    setSelectedCategory(value);
  };

  useEffect(() => {
    if (prefilledData) {
      setSelectedCategory(prefilledData.issue || "");

      setRemarks({ [prefilledData.issue]: prefilledData.remark });

      const options = prefilledData?.types?.reduce((acc, item) => {
        if (!acc[prefilledData.issue]) {
          acc[prefilledData.issue] = [];
        }
        acc[prefilledData.issue].push({
          value: item.type,
          subType: item.subtype || [],
        });
        return acc;
      }, {});
      setSelectedOptions(options);

      const expanded = {};
      expanded[prefilledData.issue] = true;
  
      prefilledData?.types?.forEach((item) => {
        expanded[item.type] = true;
        if (item.subtype && item.subtype.length > 0) {
          item.subtype.forEach((subtype) => {
            expanded[subtype] = true; 
          });
        }
      });
      setExpandedSections(expanded);
    }
  }, [prefilledData]);

  const toggleSection = (section, type) => {
    if (prefilledData && prefilledData.issue && section === prefilledData.issue) {
      return;
    }
    if (type === "issue") {
      setExpandedSections((prev) => {
        const newSections = {};
        Object.keys(prev).forEach((key) => {
          newSections[key] = false;
        });
        newSections[section] = !prev[section];
        return newSections;
      });
      // setSelectedOptions([]);
    } else {
      setExpandedSections((prev) => ({
        ...prev,
        [section]: !prev[section],
      }));
    }
  };

  const handleCheckboxSelection = (category , parentValue = "", value, hasNestedOptions, optionArr, type = "") => {
    setSelectedOptions((prev) => {
      const updatedOptions = JSON.parse(JSON.stringify({ ...prev }));
      let existingOptions = JSON.parse(JSON.stringify(updatedOptions[category] || []));
      if (existingOptions) {  
        const optionIndex = existingOptions.findIndex((opt) => opt.value === parentValue);
        if (type === "subType") {
          const subOptionIndex = existingOptions.findIndex((opt) => opt.value === value);
          if (subOptionIndex !== -1) {
            existingOptions.splice(subOptionIndex, 1);
          } else {
            existingOptions.push({ value, subType: [] });
          }
        } else if(type === "subLayer" && optionIndex != -1){
          if (optionIndex !== -1) {
            const parentOption = existingOptions[optionIndex];
            if (parentOption.subType.includes(value)) {
              parentOption.subType = parentOption.subType.filter((item) => item !== value);
                if (parentOption.subType.length === 0) {
                  existingOptions.splice(optionIndex, 1);
                }
            } 
            else {
              parentOption.subType.push(value);
            }
          } 
          else {
            existingOptions.push({ value: value, subType: [] });
          }
          updatedOptions[category] = existingOptions;
          return updatedOptions;
        }
        return {...prev, [category]: existingOptions}

      } 
      else {
        const newOption = type === 'subType' ? { value, subType: [] } : { value: parentValue, subType: [value] };
        return {...prev, [category] : newOption};
      }
    });
  };

  const renderOptions = (options, category, parentKey = "", type = "", parentValue = "") => {
    return (
      options &&
      options.length > 0 &&
      options.map((option, index) => {
        const key = `${parentKey}_${option.value}_${index}`;
        const hasNestedOptions = option.optionArr && option.optionArr.length > 0;
        
        const isChecked = type === "subLayer"
          ? (selectedOptions?.[category] || []).some(
              (opt) => opt.value === parentValue && opt.subType.includes(option.value)
            )
          : (selectedOptions?.[category] || []).some((opt) => opt.value === option.value);

        return (
          <Box key={key} sx={{ marginLeft: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#3476CD",
                    "&.Mui-checked": {
                      color: "#3476CD",
                    },
                  }}
                  onClick={(e) => e.stopPropagation()}
                  checked={isChecked}
                  onChange={(e) =>{
                    e.stopPropagation();
                    handleCheckboxSelection(
                      category,
                      parentValue,
                      option.value,
                      hasNestedOptions,
                      option.optionArr || [],
                      type
                    )
                  }
                  }
                />
              }
              label={option.label}
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#393939",
                  fontSize: "12px",
                  fontWeight: type==='subType'? '500' : '400'
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                hasNestedOptions && toggleSection(option.value, "subType")}}
            />
  
            {hasNestedOptions && (
              // <Collapse in={expandedSections[option.value]}>
              <>
                {renderOptions(option.optionArr, category, key, "subLayer", option.value)}
              </>
              // </Collapse>
            )}
          </Box>
        );
      })
    );
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const debounceTimeout = useRef(null);

  const handleRemarksChange = (category, text) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      const wordCount = text.trim().split(/\s+/).length;
      if (wordCount > 100) {
        toast.error("Remarks cannot exceed 100 words.");
        return;
      }
      setRemarks((prev) => ({
        ...prev,
        [category]: text,
      }));
    }, 300);
  };


  const validateInputArray = (inputArray, data, category) => {
    return (inputArray[category] || []).every((inputItem) => {
      const matchingSample = data.find((sampleItem) =>
        sampleItem.optionArray.some((option) => option.value === inputItem.value)
      );
      if (matchingSample) {
        const matchingOption = matchingSample.optionArray.find((option) => option.value === inputItem.value);
        if (matchingOption.optionArr && matchingOption.optionArr.length > 0) {
          return inputItem.subType && inputItem.subType.length > 0;
        }
      }
      return true;
    });
  };
  

  const validateRemarks = (category) => {
    const inputRemark = remarks[category];
    if (!inputRemark || inputRemark.trim() === "") {
      toast.error("Remarks cannot be empty");
      return false;
    } else if (inputRemark.split(/\s+/).length < 10) {
      toast.error("Remarks cannot be less than 10 words");
      return false;
    } else {
      return true;
    }
  };
  
  const transformedData = (inputData,category)=>{
    if(inputData[category] && inputData[category].length > 0){
      if(validateInputArray(inputData[category],data)){
        return inputData[category].map(item => ({
          type: item.value,
          subtype: item.subType
        }))     
      }
      else{
        toast.error('Please Select an Option')
      }
    }
    else{
      toast.error('Please Select an Issue')
    }
  }

  const handleIssueSave = (issue) => {
    const issueOptions = selectedOptions[issue] || [];
    if (!validateRemarks(issue)) {
      return;
    }
  
    const transformedOptions = transformedData(selectedOptions, issue);
    if (!transformedOptions) {
      return;
    }
  
    let raw = {
      issue,
      appId: id,
      types: transformedOptions,
      remark: remarks[issue],
    }
    if(prefilledData){
      raw = {
        ...raw,
        complaintId:prefilledData._id
      }
    }
    if(prefilledData){
      updateComplaintsService(raw)
      .then((response) => {
        if(response.status && response.status === 200){
          navigate(`/central/${activeHospitalId}/${id}`)
          toast.success('Complaint updated successfully')
  
        }
        // console.log("Complaint raised successfully", response);
      })
      .catch((error) => {
        console.error("Error updated complaint", error);
      });
    }
    else{
      raiseComplaints(raw)
      .then((response) => {
        if(response.status && response.status === 200){
          navigate(`/central/${activeHospitalId}/${id}`)
          toast.success('Complaint raised successfully')
  
        }
        // console.log("Complaint raised successfully", response);
      })
      .catch((error) => {
        console.error("Error raising complaint", error);
      });
    }

  }
  return (
    <Box sx={{ padding: "20px", border: "1px solid #ccc", borderRadius: "8px" }}>
      <Typography variant="h6" gutterBottom sx={{ color: "#3476CD", fontSize: "14px" }}>
        Please Select an Issue:
      </Typography>
      <FormControl component="fieldset" sx={{width:'100%'}}>
        <RadioGroup
          name="complaint-category"
          value={selectedCategory}
          onChange={(e) => handleCategorySelection(e.target.value)}
        >
          {data.map((category) => {
            return (
            <Box
              key={category.value}
              sx={{
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "4px",
                padding: "2px 10px",
              }}
            >
              <FormControlLabel
                value={category.value}
                control={
                  <Radio
                    sx={{
                      color: "#3476CD",
                      "&.Mui-checked": {
                        color: "#3476CD",
                      },
                    }}
                onClick={() => toggleSection(category.value, "issue")}
                  />
                }
                label={category.label}
                sx={{ color: "#3476CD" }}
              />
              <Collapse in={expandedSections[category.value]}>
                {category.value === selectedCategory && renderOptions(category.optionArray, category.value, category.value, "subType", category.value)}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "10px", color: "#1E1E1E" }}
                  >
                    Remarks:
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    defaultValue={remarks[category.value]}
                    sx={{ fontSize: "12px" }}
                    placeholder="Describe the issue..."
                    onChange={(e) => handleRemarksChange(category.value, e.target.value)}
                  />
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "8px", color: "#B0B0B0", marginTop: "10px" }}
                  >
                    Note: Min 10 words required
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      margin: "10px auto",
                      backgroundColor: "#3476CD",
                      border: "0.5px solid #3476CD",
                      borderRadius: "4px",
                      width: "100px",
                      height: "30px",
                    }}
                    onClick={() => handleIssueSave(selectedCategory)}
                  >
                    {prefilledData ? "Update" : "Save"}
                  </Button>
                </Box>
              </Collapse>
            </Box>
          )})}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default CentralComplaintSection;
