import React, { useEffect } from 'react'
import { CentralCommonHeader } from '../../component/CommonHeader/CentralCommonHeader'
import { CentralHospitalPatientCard } from '../../component/CentralHospitalPatientCard/CentralHospitalPatientCard'
import { useDispatch, useSelector } from 'react-redux';
import { centralHospitalActions, centralPatientActions } from '../../../_actions';
import './centralPatientList.css';
import { useNavigate } from 'react-router-dom';
import { hospitalDataConstants } from '../../../_constants';
import { centralPatientConstants } from '../../../_constants/centralConstant/centralPatient.constant';
import { CircularProgress } from '@mui/material';

export const CentralPatientList = () => {
  const dispatch = useDispatch();
  let patientList = useSelector(state => state.centralPatients)
  let activeHospitalId = useSelector(state=>state.centralHospitalData?.activeHospitalId)
  let activeHospitalName = useSelector(state=>state.centralHospitalData?.activeHospitalName)
  const navigate = useNavigate()
  const patientCount = patientList?.centralPatients?.count
  const patientListData = patientList?.centralPatients?.data;
  const centraHospitalData = useSelector(state => state.centralHospitalData?.listData)

  let findActiveHospitalId = ''
  if(activeHospitalId && !window.location.search.includes("accessToken")) {
    findActiveHospitalId = activeHospitalId
  }
  else{
      const parts = window.location.pathname.split('/');
      const id = parts[2]; 
      findActiveHospitalId  = id
      if(centraHospitalData?.length){
        centraHospitalData.forEach(element => {
          if(element.hospitalId === id){
            activeHospitalName = element.hospitalName
          }
      });
      }
  }

  useEffect(() => {
    dispatch(centralPatientActions.getCentralPatientList({hospitalId:findActiveHospitalId}));
    if(window.location.search.includes("accessToken")){
      dispatch(centralHospitalActions.getCentralHospitalList());
    }

    return () => dispatch(centralPatientActions.clearCentralPatientList());
  }, []);

  const handleBackButton = () =>{
    navigate(`/central`)
    dispatch(centralPatientActions.clearCentralPatientList());
    dispatch({
      type:hospitalDataConstants.SAVE_ACTIVE_CENTRAL_HOSPITAL_DATA,
      payload:null
    })
  }

  const handlePatientCard = (patientId, patientName) => {
      if(patientId && patientName){
        dispatch({
            type:centralPatientConstants.SAVE_ACTIVE_CENTRAL_PATIENT_DATA,
            payload:{patientId,patientName}
        })
    }
    navigate(`/central/${findActiveHospitalId}/${patientId}`)
    }
    const renderAllPatientCards = () => patientListData && patientListData?.map((item) => <CentralHospitalPatientCard item = {item} handlePatientCard= {handlePatientCard}/>)

  return (
    <div className='patientListParentContainer'>
    <CentralCommonHeader title={`${activeHospitalName}`} backIcon={true} backArrowFunction = {handleBackButton} filterIcon={true} dateFilter={true} itemCount = {patientCount}/>
    {
      !patientList?.isCentralDataFetched ? (<div className='patientListLoaderContainer'>
      <CircularProgress />
      </div>) :
        patientListData && patientListData.length>0 ? 
        <div className="patientCardMainContainer">{renderAllPatientCards()} </div>
        :
        (
          <div className="noResultFound">No results found</div>
        )
    }
    </div>
  )
}
