const PROVIDED_BY = [
  {
    "label": "Vendor",
    "value": "vendor"
  },
  {
    "label": "Doctor",
    "value": "doctor"
  },
  {
    "label": "Hospital",
    "value": "hospital"
  },
  {
    "label": "Pristyn",
    "value": "pristyn"
  },
]

const AVAILABILITY = [
  {
    "label": "Order Placed",
    "value": "Order Placed"
  },
  {
    "label": "In Stock",
    "value": "In Stock, will be made available "
  },
  {
    "label": "Arrived at Hospital",
    "value": "Arrived at Hospital"
  },
]

const UTILIZATION_STATUS = [
  {
    "label": "Pending",
    "value": "pending"
  },
  {
    "label": "Used",
    "value": "used"
  },
  {
    "label": "Not Used",
    "value": "notUsed"
  },
]

const EQUIPMENT_TYPE = [
  {
    "label": "Equipment",
    "value": "Equipment"
  },
  {
    "label": "Consumable",
    "value": "Consumable"
  },
  {
    "label": "Implant",
    "value": "Implant"
  },
]

const EMR_TYPE_LIST = [
  { mappedCategory: 'aesthetics', category: 'Aesthetics', type: 'web' },
  { mappedCategory: '', category: 'Ayurveda', type: 'web' },
  { mappedCategory: '', category: 'BPH', type: 'native' },
  { mappedCategory: 'breast', category: 'Breast', type: 'web' },
  { mappedCategory: '', category: 'Cosmetic Gynaecology', type: 'web' },
  { mappedCategory: '', category: 'Covid-19', type: 'web' },
  { mappedCategory: '', category: 'Dental', type: 'web' },
  { mappedCategory: '', category: 'Dermatology', type: 'web' },
  { mappedCategory: '', category: 'Diagnostic', type: 'web' },
  { mappedCategory: 'ent', category: 'ENT', type: 'native' },
  { mappedCategory: '', category: 'General', type: 'web' },
  { mappedCategory: 'general-gynae', category: 'General Gynaecology', type: 'web' },
  { mappedCategory: '', category: 'General Physician', type: 'web' },
  { mappedCategory: 'general-gynae', category: 'Gynaecology', type: 'web' },
  { mappedCategory: 'general-gynae', category: 'Gynaecology MTP', type: 'web' },
  { mappedCategory: 'hair-transplant', category: 'HairTransplant', type: 'web' },
  { mappedCategory: '', category: 'IVF', type: 'web' },
  { mappedCategory: 'kidneyStone', category: 'Kidney Stone', type: 'native' },
  { mappedCategory: 'laparoscopy', category: 'Laparoscopy', type: 'web' },
  { mappedCategory: '', category: 'Lasik', type: 'native' },
  { mappedCategory: '', category: 'Online Consulting', type: 'web' },
  { mappedCategory: '', category: 'Ophthalmology', type: 'native' },
  { mappedCategory: '', category: 'Orthopaedics', type: 'native' },
  { mappedCategory: 'proctology', category: 'Pilonidal Sinus', type: 'web' },
  { mappedCategory: 'proctology', category: 'Proctology', type: 'web' },
  { mappedCategory: 'general-gynae', category: 'Surgical Gynaecology', type: 'web' },
  { mappedCategory: 'urology', category: 'Urology', type: 'web' },
  { mappedCategory: 'urology', category: 'Urology 2', type: 'web' },
  { mappedCategory: 'vascular', category: 'Vascular', type: 'web' },
  { mappedCategory: '', category: 'Weight Loss', type: 'native' },
]

const EQUIPMENT_CATEGORY = {
  "Equipment": {
    "Laparoscopy": [
      "ETO Lapset",
      "Harmonic Set"
    ],
    "General Gynaecology": [
      "Uterine Manipulator",
      "Ligasure",
      "Hysteroscope",
      "Morcellator",
      "Harmonic",
      "Complete Lap Set",
      "Vessel Sealer",
      "Bipolar Sealer",
      "Lap Tower",
      "Lapset",
      "DNC Set",
      "TAH entire open set",
      "Complete lapset with Vessel Sealer"
    ],
    "Gynaecology": [
      "Uterine Manipulator",
      "Ligasure",
      "Hysteroscope",
      "Morcellator",
      "Harmonic",
      "Complete Lap Set",
      "Vessel Sealer",
      "Bipolar Sealer",
      "Lap Tower",
      "Lapset",
      "DNC Set",
      "TAH entire open set",
      "Complete lapset with Vessel Sealer"
    ],
    "Surgical Gynaecology": [
      "Uterine Manipulator",
      "Ligasure",
      "Hysteroscope",
      "Morcellator",
      "Harmonic",
      "Complete Lap Set",
      "Vessel Sealer",
      "Bipolar Sealer",
      "Lap Tower",
      "Lapset",
      "DNC Set",
      "TAH entire open set",
      "Complete lapset with Vessel Sealer"
    ],
    "Gynaecology MTP": [
      "Uterine Manipulator",
      "Ligasure",
      "Hysteroscope",
      "Morcellator",
      "Harmonic",
      "Complete Lap Set",
      "Vessel Sealer",
      "Bipolar Sealer",
      "Lap Tower",
      "Lapset",
      "DNC Set",
      "TAH entire open set",
      "Complete lapset with Vessel Sealer"
    ],
    "Cosmetic Gynaecology": [
      "Uterine Manipulator",
      "Ligasure",
      "Hysteroscope",
      "Morcellator",
      "Harmonic",
      "Complete Lap Set",
      "Vessel Sealer",
      "Bipolar Sealer",
      "Lap Tower",
      "Lapset",
      "DNC Set",
      "TAH entire open set",
      "Complete lapset with Vessel Sealer"
    ],
    "Kidney Stone": [
      "Cystoscope",
      "Holmium Laser and Scope",
      "Light camera",
      "Holmium Laser",
      "Laproscopy Set",
      "C arm machine",
      "ESWL Machine",
      "Laser"
    ],
    "Proctology": [
      "Laser"
    ],
    "Vascular": [
      "ECO Microwave Antenna",
      "Laser",
      "Microscope (×12–20)",
      "Fiber"
    ],
    "Surgical ENT": [
      "Debrider",
      "Endoscopy unit",
      "Microscope",
      "Drill machine",
      "Endoscope Unit",
      "Coblator with Wands"
    ],
    "ENT": [
      "Debrider",
      "Endoscopy unit",
      "Microscope",
      "Drill machine",
      "Endoscope Unit",
      "Coblator with Wands"
    ],
    "Breast": [
      "Breast Retrator with Light source",
      "Fat Grafting set",
      "Fat Collector canister jar with stand",
      "Infiltration machine",
      "Liposuction Machine",
      "Cannula set",
      "Tubing",
      "Rhinoplasty set",
      "Vaser Liposuction Machine",
      "Fat grafting set"
    ],
    "Aesthetics": [
      "Breast Retrator with Light source",
      "Fat Grafting set",
      "Fat Collector canister jar with stand",
      "Infiltration machine",
      "Liposuction Machine",
      "Cannula set",
      "Tubing",
      "Rhinoplasty set",
      "Vaser Liposuction Machine",
      "Fat grafting set"
    ],
    "Urology": [
      "Laser"
    ],
    "Orthopaedics": [
      "Arthroscopy Tower"
    ],
    "Weight Loss": [
      "Endoscopy Unit",
      "Bariatric Lapset and Instrument",
      "Bariatric Harmonic and Bariatric probe",
      "Liver retractor"
    ]
  },
  "Consumable": {
    "Laparoscopy": [
      "Mesh",
      "Tacker",
      "Suture",
      "Trocar",
      "Endoloop"
    ],
    "General Gynaecology": [
      "Covidien V lock Suture-0 No.",
      "Suture-HV49 - V-Loc 180 Absorbable",
      "1-Vicryl",
      "1-Prolene",
      "2-0 Ethilon Suture Material",
      "1-PDS",
      "2-0 Ethilon",
      "Falope rings"
    ],
    "Gynaecology": [
      "Covidien V lock Suture-0 No.",
      "Suture-HV49 - V-Loc 180 Absorbable",
      "1-Vicryl",
      "1-Prolene",
      "2-0 Ethilon Suture Material",
      "1-PDS",
      "2-0 Ethilon",
      "Falope rings"
    ],
    "Surgical Gynaecology": [
      "Covidien V lock Suture-0 No.",
      "Suture-HV49 - V-Loc 180 Absorbable",
      "1-Vicryl",
      "1-Prolene",
      "2-0 Ethilon Suture Material",
      "1-PDS",
      "2-0 Ethilon",
      "Falope rings"
    ],
    "Gynaecology MTP": [
      "Covidien V lock Suture-0 No.",
      "Suture-HV49 - V-Loc 180 Absorbable",
      "1-Vicryl",
      "1-Prolene",
      "2-0 Ethilon Suture Material",
      "1-PDS",
      "2-0 Ethilon",
      "Falope rings"
    ],
    "Cosmetic Gynaecology": [
      "Covidien V lock Suture-0 No.",
      "Suture-HV49 - V-Loc 180 Absorbable",
      "1-Vicryl",
      "1-Prolene",
      "2-0 Ethilon Suture Material",
      "1-PDS",
      "2-0 Ethilon",
      "Falope rings"
    ],
    "Kidney Stone": [
      "DJ Stent",
      "Basket & Access Sheath",
      "Guidewire"
    ],
    "Proctology": [
      "Stapler"
    ],
    "Vascular": [
      "Stocking",
      "Customized Stocking",
      "6Fr Sheath",
      "Fiber",
      "Needle driver",
      "Jeweller's forceps",
      "Coils (cook) - 10",
      "Slip cath/5 fr - 1",
      "Teurmo 0.035 - 1",
      "Conp sheaths 6 fr 90 cm - 1"
    ],
    "ENT": [
      "Blades 0,40,60",
      "Burr",
      "Wand",
      "Grommet"
    ],
    "Surgical ENT": [
      "Blades 0,40,60",
      "Burr",
      "Wand",
      "Grommet"
    ],
    "Breast": [
      "Customized Garment",
      "Funnel",
      "Chin Strap"
    ],
    "Aesthetics": [
      "Customized Garment",
      "Funnel",
      "Chin Strap"
    ],
    "Urology": [
      "Stapler"
    ],
    "Weight Loss": [
      "Stapler",
      "Trocar Tube",
      "Gastric Balloon",
      "Allurion Stylet",
      "Pressure Bag",
      "Filler Kit"
    ]
  },
  "Implant": {
    "Vascular": [
      "Vascular Implants",
      "Venaseal kit - 2"
    ],
    "Breast": [
      "Breast Implant",
      "Nasal Implant",
      "Compression vest"
    ],
    "Aesthetics": [
      "Breast Implant",
      "Nasal Implant",
      "Compression vest"
    ],
    "Ophthalmology": [
      "Lens"
    ],
    "Orthopaedics": [
      "Ortho Implant"
    ]
  }
}

export { PROVIDED_BY, AVAILABILITY, UTILIZATION_STATUS, EMR_TYPE_LIST, EQUIPMENT_TYPE, EQUIPMENT_CATEGORY }