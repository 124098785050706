import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; 
import "./centralDateTimePicker.css";

function CentralDateTimePicker({
  value='',
  placeholder,
  label='',
  handleOnChange = () =>{},
  error = false,
  disabled = false,
  helperText = '',
  width = '47%',
  jsonDetails = {},
  handleGlobalChanges = () => {},
  name = '',
  globalValues = '',
  setGlobalValues = () => {}
}) {
  const [dateTimeValue, setDateTimeValue] = useState(null);
  const [isOtEndTimeDisabled, setIsOtEndTimeDisabled] = useState(false);
  const inputStyles = {
    height: '30px',
    fontSize:'12px'
  };

  useEffect(() => {
    if (jsonDetails?.key === 'actual_ot_start_time' && jsonDetails.value) {
      setGlobalValues((prev) => ({ ...prev, actualOtStartTime: dayjs(jsonDetails.value) }));
    }
  }, [jsonDetails]);

  useEffect(() => {
    if (jsonDetails?.key === 'actual_ot_end_time') {
      if (globalValues.actualOtStartTime) {
        setIsOtEndTimeDisabled(false);
      } else {
        setIsOtEndTimeDisabled(true);
      }
    }
  }, [jsonDetails, globalValues]);

  const defaultOnChange = (value) => {
    handleGlobalChanges(jsonDetails?.postKey);
    setDateTimeValue(value);

    if (jsonDetails?.key === 'actual_ot_start_time') {
      setGlobalValues((prev) => ({
        ...prev,
        actualOtStartTime: dayjs(value),
        actualOtEndTime: prev.actualOtEndTime && dayjs(value).isAfter(prev.actualOtEndTime) ? null : prev.actualOtEndTime
      }));
    } else if (jsonDetails?.key === 'actual_ot_end_time') {
      setGlobalValues((prev) => ({ ...prev, actualOtEndTime: dayjs(value) }));
    }

    handleOnChange(value);
  };
  const handleIconClick = () => {
    setIsPickerOpen((prevOpen) => !prevOpen);
  };

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <FormControl sx={{ width: { width }, maxWidth: '350px', marginTop:'20px' }} variant="outlined" disabled={disabled || (jsonDetails && jsonDetails?.disable)}>
      <FormHelperText id="input-text-field-label" className='inputFieldLabel'>{label || (jsonDetails &&jsonDetails?.label)}</FormHelperText>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
        onOpen={() => setIsPickerOpen(true)}
        onClose={() => setIsPickerOpen(false)}
        open={isPickerOpen}
          renderInput={(props) => <TextField onClick={handleIconClick} {...props} InputProps={{ style: inputStyles }} name= {name || (jsonDetails && jsonDetails?.postKey)} placeholder={placeholder || 'Select Date'} InputLabelProps={{ shrink: false }}/>}
          value={value && dayjs(value) || dateTimeValue && dayjs(dateTimeValue) || (jsonDetails?.value && dayjs(jsonDetails?.value)) || null}
          onChange={defaultOnChange}
          disabled={disabled || (jsonDetails && jsonDetails?.disable) || isOtEndTimeDisabled}
          minDateTime={jsonDetails?.key === 'actual_ot_end_time' ? globalValues.actualOtStartTime : null}
          maxDateTime={jsonDetails?.key === 'actual_ot_start_time' ? globalValues.actualOtEndTime : null}
        />
      </LocalizationProvider>
      {error && helperText ? (
        <FormHelperText error id="" className="inputFieldError">
          {helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </FormControl>
  );
}

export default CentralDateTimePicker;